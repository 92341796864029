import React from 'react';
import projectImage
  from '../../../assets/tiago_page/projects_section/chatgpt.jpg';

const LulAI = () => {
  const paragraph1 = (
    <>
        LulAI is a groundbreaking AI-driven platform
        transforming both in-store and online shopping
        experiences with personalized recommendations and
        real-time assistance. Leveraging advanced
        artificial intelligence, LulAI makes shopping
        more intuitive, enjoyable, and tailored to
        individual needs, creating a more engaging
        retail environment.
    </>
  );

  const paragraph2 = (
    <>
        LulAI connects customers with the products they
        need through innovative solutions like self-service
        kiosks, a mobile app, and website-integrated
        chatbots. These tools offer tailored suggestions
        based on customer inquiries, preferences, and
        location. Customers can ask where items are
        located or what they need for a task or meal,
        receiving a personalized list with a map showing
        the aisle and shelf of each product. This helps
        customers find exactly what they&apos;re looking for
        and discover new products they might not have
        considered. Retailers gain deep insights into
        consumer behavior, enabling them to optimize
        operations, inventory management, and marketing
        strategies, ultimately boosting sales and
        customer loyalty.
    </>
  );

  const paragraph3 = (
    <>
        By adopting LulAI&apos;s solutions, retailers can
        create a personalized shopping experience that
        resonates with customers. The platform
        integrates smoothly with existing systems,
        allowing businesses to quickly implement and
        start benefiting from a smarter, more efficient
        retail environment where customer satisfaction
        and operational efficiency are significantly
        improved.
    </>
  );

  return (
    <section id="lulai" className="bg-white text-gray-800">
      {/* Full-width Image */}
      <img
        src={projectImage}
        alt="LulAI"
        className="w-full h-64 md:h-96 object-cover rounded-lg mb-8"
      />

      {/* Content Section */}
      <div className="container mx-auto py-12 px-8">
        <h1 className="text-4xl font-bold text-center mb-8">
                    LulAI
        </h1>
        <div className="text-left">
          <p className="text-lg mb-6">{paragraph1}</p>
          <p className="text-lg mb-6">{paragraph2}</p>
          <p className="text-lg mb-6">
                        Key features of LulAI include:
          </p>
          <ul className="list-disc list-inside text-lg mb-6">
            <li>An in-store kiosk with a chatbot that
                    helps customers find item locations and
                    prints lists with aisle and shelf details.
            </li>
            <li>Mobile app with kiosk features, finding
                nearby LulAI-supported stores and connecting
                customers with available products.
            </li>
            <li>Website-integrated chatbot that answers
                    customer queries and provides product suggestions.
            </li>
            <li>Valuable insights and analytics for retailers
                    to optimize operations and enhance customer engagement.
            </li>
            <li>Seamless integration with existing websites
                and retail systems.</li>
          </ul>
          <p className="text-lg mb-6">{paragraph3}</p>
        </div>
      </div>
    </section>
  );
};

export default LulAI;
